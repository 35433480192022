// @ts-ignore
import React        from "react"
import "../css/index.css"
import {FindMd}     from '../lib/FindMd'
import {ThisLayout} from '../components/thisLayout'

const NotFound = ({data}) => {
  let md_data = FindMd({file_name: '404.md'})
  return <>
    <ThisLayout md_data={md_data}/>
  </>
}

export default NotFound
